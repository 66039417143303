<template>
  <div v-if="department">
    <PageTitle
      :title="department.title"
      :alias="department.alias"
      type="departments"
      :ShowAlias="true"
    />
    <!-- {{ department }} -->
    <div class="container py-4">
      <div class="row">


        
        <div class="details">
          <h1>{{ department.sub_title }}</h1>
          <p class="card-text" v-html="department.fulltext"></p>
        </div>
      </div>
    </div>

    <!-- Display doctors section -->
    <!-- Display doctors section -->
    <h2 class="container" v-if="doctors.length > 0">
      Doctors of {{ department.title }}
    </h2>

    <div class="container text-center my-4 " v-if="doctors.length === 0">
      <h2>There's no doctor in this department</h2>
    </div>

    <div
      class="doctors-section dlist container mt-3 mb-5"
      v-if="doctors && doctors.length > 0">
      <h3>Doctors in this Department</h3>
      <div class="container">
        <div class="row gy-4">
          <div
            class="col-lg-3 col-md-6 col-sm-12"
            v-for="doctor in doctors"
            :key="doctor.id"
            @click="redirectToDoctorDetails(doctor.id)"
          >
            <div
              class="team_member card text-center d-flex flex-column align-items-center"
            >
              <div class="team_img">
                <img
                  style="
                    width: auto;
                    height: 550px;
                    border-radius: 0 0 40px 40px;
                  "
                  :src="
                    doctor.profile_photo
                      ? `${this.$apiBaseUrl}fdrives/sid/cityhospital/doctors/${doctor.profile_photo}`
                      : defaultImage
                  "
                  :alt="doctor.name"
                />
              </div>
              <div class="department_team_details text-center">
                <strong class="team_name py-2">{{ doctor.name }}</strong>
                <div class="details">
                  <p style="color: white">
                    {{ truncateText(doctor.degree, 5) }}
                  </p>
                </div>
                <div class="text-center doctor_btn" style="color: green">
                  <a
                    @click.prevent="redirectToDoctorDetails(doctor.id)"
                    style="cursor: pointer"
                    >View Profile</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from "@vueuse/head";
import PageTitle from "./PageTitle.vue";

export default {
  name: "DepartmentDetails",
  components: {
    PageTitle,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      department: null,
      doctors: [], // Initialize as an empty array
    };
  },

  watch: {
    "$route.params.alias": {
      immediate: true,
      handler() {
        this.fetchDepartmentDetail();
      },
    },
  },
  methods: {
    async fetchDepartmentDetail() {
      try {
        let deptalias = this.$route.params.alias;
        let url =
          this.$apiBaseUrl +
          `website/website_api/content/${deptalias}?access_key=` +
          this.$apiAccessKey +
          `&debug=1`;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        const data = result.content;
        this.department = data;
        // For meta tag
        useHead({
          title: this.department.meta_title || this.department.title,
          meta: [
            {
              name: "description",
              content: this.department.meta_description || "",
            },
            {
              name: "keywords",
              content: this.department.meta_keywords || "",
            },
          ],
        });
        // console.log("details", this.department);

        // // Find the department by alias
        // this.department = data?.find(
        //   (department) => department.alias == this.$route.params.alias
        // );

        // Fetch doctors if the department is found
        if (this.department && this.department.id) {
          this.fetchDepartmentDoctors(this.department.id);
        }
      } catch (error) {
        console.error("Error fetching department details:", error);
      }
    },
    async fetchDepartmentDoctors(departmentId) {
      try {
        let url =
          this.$apiBaseUrl +
          `pip/pip_api/doctors?website_content_id=${departmentId}&access_key=${this.$apiAccessKey}`;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();

        // Assign doctors data if it's an array and has elements
        this.doctors =
          Array.isArray(result.doctors) && result.doctors.length > 0
            ? result.doctors
            : [];

        console.log("Doctors Data:", this.doctors);
      } catch (error) {
        console.error("Error fetching doctors details:", error);
      }
    },
    truncateText(text, wordLimit) {
      if (!text) return "";
      const words = text.split(" ");
      return words.length > wordLimit
        ? words.slice(0, wordLimit).join(" ") + "..."
        : text;
    },
    redirectToDoctorDetails(doctorId) {
      this.$router.push({ name: "DoctorDetails", params: { id: doctorId } });
    },
  },
  mounted() {
    //this.fetchDepartmentDetail();
    // this.fetchDepartmentDoctors();
  },
};
</script>
