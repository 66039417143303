<template>
  <div v-if="!loading">
    <footer class="">
      <footer id="footer" class="footer bg-light text-dark">
        <div class="container pt-5">
          <div class="row gy-4">
            <div class="col-lg-4 col-md-4 footer-about">
              <a href="index.html" class="logo d-flex align-items-center">
                <span class="sitename">{{
                  sidSite?.app_setting?.organization_information?.name
                }}</span>
              </a>
              <div class="footer-contact mb-1 pt-3">
                <p class="pr-5">
                  {{
                    sidSite?.app_setting?.organization_information?.["address"]
                  }}
                </p>

                <p class="mt-3">
                  <strong>Phone:</strong>
                  <span>{{
                    sidSite?.app_setting?.organization_information?.[
                      "hot-number"
                    ]
                  }}</span>
                </p>
                <p>
                  <strong>Email:</strong>
                  <span>{{
                    sidSite?.app_setting?.organization_information?.[
                      "contact-email"
                    ]
                  }}</span>
                </p>
              </div>
              <div class="social-links d-flex mt-4">
                <a
                  target="_blank"
                  href="https://www.youtube.com/channel/UC6Zlt_DnTm8CAUC45jxpFNQ"
                  ><i class="bi bi-youtube"></i
                ></a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/CityHospitalBd?mibextid=ZbWKwL"
                  ><i class="bi bi-facebook"></i
                ></a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/accounts/login/?next=https%3A%2F%2Fwww.instagram.com%2Fcityhospitall%2F%3Figsh%3DMXczbDR1NjhmMnVnbA%253D%253D&is_from_rle"
                  ><i class="bi bi-instagram"></i
                ></a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/city-hospital-ltd/"
                  ><i class="bi bi-linkedin"></i
                ></a>
              </div>
              <div class="visitor_count py-2">
                <strong> Visitor Count : </strong>
                <!-- <a href="https://www.hitwebcounter.com" target="_blank">
                  <img
                    src="https://hitwebcounter.com/counter/counter.php?page=17996924&style=0009&nbdigits=5&type=page&initCount=0"
                    title="Counter Widget"
                    Alt="Visit counter For Websites"
                    border="0"
                /></a> -->

                <!-- hitwebcounter Code START -->

                <a href="https://www.freecounterstat.com" title="free website counters"><img src="https://counter6.optistats.ovh/private/freecounterstat.php?c=pcwbwwuw56w9m52wp9re6fd2yfr4x9b5" border="0" title="free website counters" alt="free website counters"></a>
                             
                             
                             
                              </div>
            </div>

            <div class="col-lg-2 col-md-3 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li><a href="/">Home</a></li>
                <li><a href="/pages/about-us">About us</a></li>
                <li><a href="/doctors">Doctors</a></li>
                <li><a href="/pages/asthar-thikana">Asthar Thikana</a></li>
              </ul>
            </div>

            <div class="col-lg-2 col-md-3 footer-links pt-5">
              <ul>
                <li>
                  <a href="/news">News &amp; Events</a>
                </li>
                <li>
                  <a href="/contact-us">Contact Us</a>
                </li>
                <li>
                  <a href="/blog">Blog</a>
                </li>
                <li>
                  <a href="/gallery">Gallery</a>
                </li>
              </ul>
            </div>

            <div class="col-lg-4 col-md-3 footer-links">
              <h4>Popular Departments</h4>
              <!-- {{ departmentContent }} -->
              <ul>
                <li
                  v-for="department in departmentContent.slice(0, 6)"
                  :key="department.id"
                >
           
                  <a
                    href="javascript:void(0)"
                    @click="goToDepartmentPage(department.alias)"
                    >{{ department.title }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="container copyright text-center mt-4">
          <p>
            © <span>Copyright</span>
            <strong class="px-1 sitename">City Hospital Limited</strong>
            <span>All Rights Reserved</span>
          </p>
          <div class="credits">
            Designed by
            <a href="https://autofysolutions.com/">Autofy Solutions</a>
          </div>
        </div>
      </footer>
    </footer>
  </div>
  <div v-else>...</div>
</template>

<script>
export default {
  name: "FooterElement",
  props: {
    sidSite: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      departmentContent: [], // No need to manage loading state here anymore
    };
  },
  async mounted() {
    this.fetchDepartmentInfo();
  },
  methods: {
    async fetchDepartmentInfo() {
      try {
        let url =
          this.$apiBaseUrl +
          `website/website_api/contents/department?access_key=` +
          this.$apiAccessKey;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Parse the response as JSON
        const result = await response.json();
        const data = result.contents;

        // Store fetched data in localStorage
        localStorage.setItem("departmentContent", JSON.stringify(data));
 
        // Update component data
        this.departmentContent = data;
      } catch (error) {
        console.error("Error fetching department data:", error);
      }
    },
    goToDepartmentPage(departmentAlias) {
      // Navigate to the department details page and pass department alias
      this.$router.push({
        name: "DepartmentDetails",
        params: { alias: departmentAlias }, // Use alias instead of ID
      });
    },
  },
};
</script>
