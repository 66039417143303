<template>
  <PageTitle title="Login Redirecting....." type="login..." />

  <section class="contact_us">
    Please wait to redirect to admin panel .... 
  </section>

  
</template>

<script>
import PageTitle from "@/views/PageTitle.vue";
import { useHead } from "@vueuse/head";

export default {
  components: {
    PageTitle,
  },
  data() {
    return {
       
    };
  },
  setup() {
    useHead({
      title: "Contact Us",
      meta: [
        {
          name: "description",
          content: "Contact with City Hospital.",
        },
        {
          name: "keywords",
          content: "news, hospital, departments, medical services, contact",
        },
      ],
    });
  },
  methods: {
    
  },
  mounted() {
 
  window.location.href = this.$apiBaseUrl + "login";
  },
};
</script>
