<template>
  <header class="sticky-top">
    <div class="header">
      <div class="d-flex align-items-center">
        <div
          class="container position-relative d-flex align-items-center justify-content-end"
        >
          <nav id="navmenu" class="navmenu d-flex align-items-center me-auto">
            <ul>
              <li
                v-for="item in menuItems"
                :key="item.id"
                :class="
                  item.children && item.children.length > 5 && !isSmallScreen
                    ? 'nav-item megamenu '
                    : 'nav-item dropdown'
                "
              >
                <template
                  v-if="
                    item.children && item.children.length > 5 && !isSmallScreen
                  "
                >
                  <!-- Mega Menu -->
                  <li class="megamenu-li">
                    <a
                      href="#"
                      class="nav-link dropdown-toggle"
                      @click.prevent="toggleDropdown"
                    >
                      {{ item.label }}
                    </a>
                    <!-- Mega Menu -->
                    <div class="dropdown-menu mega-menu p-2">
                      <div
                        v-for="(column, index) in splitChildrenIntoColumns(
                          item.children
                        )"
                        :key="index"
                        class="row"
                      >
                        <div
                          v-for="child in column"
                          :key="child.id"
                          class="col-md-4"
                        >
                          <router-link
                            :to="`/${child.link}`"
                            class="dropdown-item mega-menu-text"
                            @click="closeMobileMenu"
                          >
                            {{ child.label }}
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </li>
                </template>

                <template v-else-if="item.children && item.children.length > 0">
                  <a
                    href="#"
                    class="nav-link dropdown-toggle"
                    @click.prevent="toggleDropdown"
                  >
                    {{ item.label }}
                  </a>
                  <ul class="dropdown-menu">
                    <li
                      v-for="child in item.children"
                      :key="child.id"
                      class="dropdown-item"
                    >
                      <router-link
                        :to="`/${child.link}`"
                        @click="closeMobileMenu"
                      >
                        <span :title="child.label">{{ child.label }}</span>
                      </router-link>
                    </li>
                  </ul>
                </template>

                <template v-else>
                  <router-link
                    :to="`/${item.link}`"
                    class="nav-link"
                    @click="closeMobileMenu"
                  >
                    {{ item.label }}
                  </router-link>
                </template>
              </li>
            </ul>

            <i class="mobile-nav-toggle d-xl-none bi bi-list"></i>
            <div class="ml-2 d-flex  mobile_top_button">
              <a
                class="cta-btn btn_white d-none d-lg-block"
                target="_blank"
                href="/doctors"
                >Find a Doctor</a
              >
              <a
                class="cta-btn d-none d-lg-block"
                target="_blank"
                :href="
                  sidSite?.app_setting?.organization_information
                    ?.doctor_appointment
                "
                >Online Doctor Appointment</a
              >
            </div>
          </nav>

          <div class="d-xl-none">
            <a
              class="cta-btn btn_white"
              style="font-weight: bold"
              target="_blank"
              href="/doctors"
              >Find a Doctor</a
            >
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderPart",
  props: {
    sidSite: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      widgets: null,
      menuItems: [],
      windowWidth: window.innerWidth,
    };
  },

  mounted() {
    this.loadMenuItems();
    this.initializeMobileMenu();
    window.addEventListener("resize", this.updateWindowWidth);
  },
  computed: {
    isSmallScreen() {
      return this.windowWidth < 1200;
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateWindowWidth);
  },
  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
    loadMenuItems() {
      const storedWidgets = localStorage.getItem("widgets");
      if (storedWidgets) {
        this.widgets = JSON.parse(storedWidgets);
        if (this.widgets && this.widgets.menu && this.widgets.menu.main_menu) {
          this.menuItems = this.widgets.menu.main_menu.map((item) => ({
            id: item.id,
            alias: item.alias,
            link: item.link,
            label: item.label,
            url: `${item.url}${item.link}`,
            children: item.children || [], // Include children for dropdown
          }));
        }
      }
    },
    initializeMobileMenu() {
      // Mobile nav toggle
      const mobileNavToggleBtn = document.querySelector(".mobile-nav-toggle");
      if (mobileNavToggleBtn) {
        mobileNavToggleBtn.addEventListener("click", this.mobileNavToggle);
      }

      // Hide mobile nav on same-page/hash links
      document.querySelectorAll("#navmenu a").forEach((navmenu) => {
        navmenu.addEventListener("click", () => {
          if (
            document
              .querySelector("body")
              .classList.contains("mobile-nav-active")
          ) {
            this.mobileNavToggle();
          }
        });
      });
    },
    mobileNavToggle() {
      document.querySelector("body").classList.toggle("mobile-nav-active");
      const mobileNavToggleBtn = document.querySelector(".mobile-nav-toggle");
      if (mobileNavToggleBtn) {
        mobileNavToggleBtn.classList.toggle("bi-x");
        mobileNavToggleBtn.classList.toggle("bi-list");
      }
    },
    closeMobileMenu() {
      document.querySelector("body").classList.remove("mobile-nav-active");
      const mobileNavToggleBtn = document.querySelector(".mobile-nav-toggle");
      if (mobileNavToggleBtn) {
        mobileNavToggleBtn.classList.remove("bi-x");
        mobileNavToggleBtn.classList.add("bi-list");
      }
    },
    toggleDropdown(event) {
      event.preventDefault();
      const dropdownMenu = event.currentTarget.nextElementSibling;
      if (dropdownMenu) {
        dropdownMenu.classList.toggle("dropdown-active");
      }
    },

    // Method to split children into columns for the megamenu
    splitChildrenIntoColumns(children) {
      // console.log(children);
      children = this.sortByLabelAsc(children);
      const perColumn = 3;
      const columns = [];
      for (let i = 0; i < children.length; i += perColumn) {
        columns.push(children.slice(i, i + perColumn));
      }

      return columns;
    },
    sortByLabelAsc(menuItems) {
      return menuItems.sort((a, b) => a.label.localeCompare(b.label));
    },
  },
};
</script>
