<template>
  <div>
    <LogoArea v-if="!loading" :sidSite="localSidSite" />
    <HeaderPart v-if="!loading" :sidSite="localSidSite"  />
    <router-view
      v-if="!loading"
      :localStoredWidgets="localStoredWidgets"
    />
    <FooterElement v-if="!loading" :sidSite="localSidSite" />
    <!-- <div v-else>Loading...</div> -->
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import FooterElement from "./components/Shared/FooterElement.vue";

import LogoArea from "./components/Shared/LogoArea.vue";
import HeaderPart from "./components/Shared/HeaderPart.vue";

export default {
  name: "App",
  components: {
    HeaderPart,
    FooterElement,
    LogoArea,
  },
  data() {
    return {
      localStoredWidgets: null,
      localSidSite: null,
      loading: true,
      debugMode: false,
      jsonVersion: 20241125,
      analyticCode: "",
    };
  },
  async mounted() {
    // Check if debug mode is enabled
    this.debugMode = localStorage.getItem("debug") === "1";

    if (this.debugMode && !localStorage.getItem("popupDismissed")) {
      this.showPopup = true;
    }

    // Fetch data when the component is mounted
    // if (this.debugMode) {
    await this.fetchData();
    AOS.init({
      duration: 600,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
    // } else {
    //   this.loading = false; // Set loading to false if maintenance page is shown
    // }
  },
  updated() {
    AOS.refresh();
  },
  methods: {
    async fetchData() {
      try {
        const queryString = window.location.search;

        // Create a URLSearchParams object
        const params = new URLSearchParams(queryString);
        const debug = params.get("debug"); // "JohnDoe"
        if (debug) {
          this.jsonVersion = Math.random().toString(16).slice(2);
        }
        const response = await fetch(
          "/apijson/assets/settings.json?v=" + this.jsonVersion
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        // Update localStorage and local state
        this.localStoredWidgets = data.widgets;
        this.localSidSite = data.sid_site;
        localStorage.setItem("widgets", JSON.stringify(data.widgets));
        localStorage.setItem("sid", JSON.stringify(data.sid_site));
        this.analyticCode = data.sid_site.analytic_code;
        console.log("analytic", this.analyticCode);
        this.insertAnalyticalCode(this.analyticCode);
        this.loading = false;
      } catch (error) {
        console.error("Error fetching data:", error);
        this.loading = false;
      }
    },
    insertAnalyticalCode(code) {
      if (!code) return;

      try {
       
        document.head.insertAdjacentHTML("beforeend", code);
      } catch (error) {
        console.error("Error inserting analytical code:", error);
      }
    },
  },
};
</script>
