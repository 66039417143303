<template>
  <div v-if="content" class="single">
    <PageTitle
      :title="content.title"
      :type="contentType"
      :alias="alias"
      :ShowAlias="true"
    />
    <div
      :class="{ container: isContainer, 'full-width': isFullWidth }"
      class="py-5"
    >
      <div class="row">
        <!-- Main Content -->
        <div class="col-lg-8" :class="mainContentClass">
          <!-- Featured Image -->
          <!-- {{ content.page_setting }} -->
          <!-- {{ content }} -->
          <div v-if="content?.is_featured_image" class="mb-4">
            <img
              :src="content.image_url"
              :alt="content.title"
              :title="content.title"
              class="img-fluid"
              style="border-radius: 20px"
            />
          </div>

          <!-- Full Text Content -->

          <div v-html="content.fulltext" class="sp-sp-content-text mb-5"></div>
          <!-- <hr /> -->
          <!-- Title and Meta -->
          <!-- <div v-if="!content.page_setting?.is_hide_title" class="mb-3">
            <h1
              style="font-size: 30px; line-height: 40px; font-weight: 500"
              class="details-title"
            >
              {{ content.title }}
            </h1>
            <p class="text-muted">
              <i class="icon far fa-calendar-alt" style="margin-right: 5px"></i>
              <a> {{ formatDate(content?.publish_date) }}</a>
            </p>
          </div> -->
        </div>

        <!-- Sidebar -->
        <div class="col-lg-4" v-if="hasSidebar" :class="sidebarClass">
          <div class="sp-sidebar-section mb-4">
            <h5 class="sidebar-title font-weight-bold" style="font-weight: bold">Popular {{ contentType }}</h5>
            <div
              v-for="post in popularPosts.slice(0, 10)"
              :key="post.title"
              
            
            >
              <!-- Post Info -->
              <div style="flex: 1; padding-right: 10px">
                <router-link
                  :to="{
                    name: 'SinglePage',
                    params: { alias: post.alias, contentType: contentType },
                  }"
                >

                  <p class="sidebar-post-title mb-2" style="font-weight: bold">
                    <i class="fas fa-building"></i> {{ post.title }}
                  </p>
                </router-link>

                <!-- <p class="sidebar-meta text-muted" style="font-size: 0.9em">
                  <i
                    class="icon far fa-calendar-alt"
                    style="margin-right: 5px"
                  ></i>
                  {{ post.publish_date }}
                </p> -->
              </div>

              <!-- Thumbnail Image -->
              <!-- <img
                :src="post.image_url"
                class="sidebar-thumb me-3"
                alt="popular post"
                style="
                  border-radius: 12px;
                  width: 80px;
                  height: 80px;
                  object-fit: cover;
                "
              /> -->
            </div>
          </div>
          <!-- Fb  -->
          <iframe
            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FCityHospitalBd%2F&tabs=timeline&width=400&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
            width="400"
            height="500"
            style="border: none; overflow: hidden"
            scrolling="no"
            frameborder="0"
            allowfullscreen="true"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>

          <div class="add-widget padding-bottom-30 mt-4">
            <img
              class="img-fluid rounded-4"
              src="https://cityhospital.autofyapps.com/fdrives/sid/cityhospital/gallery/hospiital-building-view/297a9513_s96462.webp"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from "@vueuse/head";
import PageTitle from "./PageTitle.vue";

export default {
  props: ["alias", "contentType"],
  components: {
    PageTitle,
  },
  data() {
    return {
      content: null,
      error: null,
      popularPosts: [],
    };
  },
 
  computed: {
    isContainer() {
      return this.content.page_template_column !== "full_width";
    },
    isFullWidth() {
      return this.content.page_template_column === "full_width";
    },
    mainContentClass() {
      switch (this.content.page_template_column) {
        case "one_column":
          return "col-lg-12";
        case "two_column_leftbar":
          return "col-lg-8";
        case "two_column_rightbar":
          return "col-lg-8";
        default:
          return "col-lg-12";
      }
    },
    hasSidebar() {
      return ["two_column_leftbar", "two_column_rightbar"].includes(
        this.content.page_template_column
      );
    },
    sidebarClass() {
      return this.content.page_template_column === "two_column_leftbar"
        ? "col-lg-4 order-lg-1"
        : "col-lg-4 order-lg-2";
    },
  },
  created() {
    this.fetchSinglePage();
    this.fetchContentList();
  },
  watch: {
    alias(newAlias, oldAlias) {
      if (newAlias !== oldAlias) {
        this.fetchSinglePage();
      }
    },
  },
  methods: {
    async fetchSinglePage() {
      try {
        const response = await fetch(
          `${this.$apiBaseUrl}website/website_api/content/${this.alias}?access_key=${this.$apiAccessKey}&debug=1`
        );
        const data = await response.json();
        if (response.ok) {
          this.content = data.content;
          // For meta tag
          useHead({
            title: this.content.meta_title || this.content.title,
            meta: [
              {
                name: "description",
                content: this.content.meta_description || "",
              },
              {
                name: "keywords",
                content: this.content.meta_keywords || "",
              },
            ],
          });
        } else {
          this.error =
            data.message || `Error fetching ${this.contentType} details`;
        }
      } catch (err) {
        this.error = "Network error, please try again later.";
      }
    },
    async fetchContentList() {
      this.popularPosts = JSON.parse(localStorage.getItem("departmentContent"));
    
        
      // try {
      //   const response = await fetch(
          
      //     `${this.$apiBaseUrl}website/website_api/contents/department?access_key=${this.$apiAccessKey}&debug=1`
      //   );
      //   const data = await response.json();
      //   console.log('poppost',data);
      //   if (response.ok) {

      //  //   this.popularPosts = data.contents;
      
      //     // alert("ok");
       
      //   } else {
      //     this.error =
      //       data.message || `Error fetching ${this.contentType} list`;
      //   }
      // } catch (err) {
      //   this.error = "Network error, please try again later.";
      // }
    },

    formatDate(dateStr) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(dateStr).toLocaleDateString(undefined, options);
    },
  },
};
</script>
