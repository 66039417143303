<template>
  <PageTitle title="Departments" type="departments" />
  <section class="all-departments py-5 dcard">
    <div class="container">
      <div class="row">
        <div
          class="col-md-4 mb-4"
          v-for="department in departmentContent"
          :key="department.id"
        >
          <div
            class="department-details p-3 border rounded d-flex flex-column h-100"
          >
            <div class="stat-img p-2 text-center">
              <i class="fas fa-stethoscope icon"></i>
            </div>
            <h2 class="text-center font-weight-bold title">
              {{ department.title }}
            </h2>
            <div class="flex-grow-1 overflow-hidden content">
              <div v-html="getTruncatedText(department.fulltext)"></div>
            </div>
            <div class="text-center mt-auto">
              <button
                class="all-btn px-3 py-1"
                @click="goToDepartmentPage(department.alias)"
              >
                More
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Pagination Controls -->
      <div class="col-lg-12 col-12">
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center mb-0">
            <!-- Previous Button -->
            <li class="page-item" :class="{ disabled: currentPage === 1 }">
              <a
                class="page-link text-dark"
                href="#"
                aria-label="Previous"
                @click.prevent="changePage(currentPage - 1)"
              >
                <span aria-hidden="true">Prev</span>
              </a>
            </li>

            <!-- Page Numbers -->
            <li
              class="page-item"
              v-for="page in totalPages"
              :key="page"
              :class="{ active: currentPage === page }"
            >
              <a
                :style="currentPage === page ? activeStyle : inactiveStyle"
                class="page-link"
                href="#"
                @click.prevent="changePage(page)"
              >
                {{ page }}
              </a>
            </li>

            <!-- Next Button -->
            <li
              class="page-item"
              :class="{ disabled: currentPage === totalPages }"
            >
              <a
                class="page-link text-dark"
                href="#"
                @click.prevent="changePage(currentPage + 1)"
              >
                <span aria-hidden="true">Next</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </section>
</template>

<script>
import PageTitle from "./PageTitle.vue";

export default {
  name: "DepartmentDoctor",
  components: {
    PageTitle,
  },
  data() {
    return {
      departmentContent: [],
      currentPage: 1,
      totalPages: 1,
      itemsPerPage: 9,
      activeStyle: {
        backgroundColor: "#00a550", // Background color for active page
        color: "#fff", // Font color for active page
      },
      inactiveStyle: {
        backgroundColor: "#fff", // Background color for inactive pages
        color: "#00a550", // Font color for inactive pages
      },
    };
  },
  methods: {
    async fetchDepartmentInfo(page = 1) {
      try {
        const url = `${this.$apiBaseUrl}website/website_api/contents/department?access_key=
        ${this.$apiAccessKey}&page=${page}&limit=${this.itemsPerPage}&order_by=title&order=asc`;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        const paging = result.paging;

        this.totalPages = paging.pages;
        this.itemsPerPage = paging.per_page;
        this.departmentContent = result.contents;
      } catch (error) {
        console.error("Error fetching department data:", error);
      }
    },
    getTruncatedText(text) {
      let words = text.split(/\s+/);
      return words.length <= 16 ? text : words.slice(0, 16).join(" ") + "...";
    },
    goToDepartmentPage(departmentAlias) {
      this.$router.push({
        name: "DepartmentDetails",
        params: { alias: departmentAlias },
      });
    },
    changePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
        this.fetchDepartmentInfo(this.currentPage);
      }
    },
  },
  mounted() {
    this.fetchDepartmentInfo(this.currentPage);
  },
};
</script>
