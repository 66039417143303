<template>
  <PageTitle title="Doctors" type="Doctors" />
  <section class="dlist">
    <div class="doctors_list pt-2">
      <div class="container">
        <h2 class="about_title">Find Your Doctor</h2>

        <div class="row gy-2">

 


          <div class="col-lg-5 col-md-10">
            <select
              class="form-select s-field"
              v-model="selectedDepartment"
              aria-label="Default select example"
            >
              <option class="p-5" value="" disabled selected>
                Select A Department
              </option>
              <option
                v-for="department in departments"
                :key="department.id"
                :value="department.id"
              >
                {{ department.title }}
              </option>

 

            </select>
          </div>
          <div class="col-lg-5 col-md-10">
            <input
              type="text"
              class="form-control s-field"
              v-model="doctorName"
              placeholder="Type Doctor Name"
            />
          </div>
          <div class="col-lg-2 col-md-2">
            <a
              @click.prevent="searchDoctor"
              style="font-size: 16px"
              class="btn mb-3"
            >
              Search
            </a>
          </div>
        </div>

        <!-- Department by alphabet -->
        <div
          class="text-center m-5 section-padding-bottom justify-center pagination-buttons"
          id="speciality-search"
          style="grid-column: span 12 / span 12; margin: 0px auto"
        >

 

          <div class="speciality-search-text">
            <p>Search by Department</p>
          </div>
          <div>
            <button
              v-for="letter in letters"
              :key="letter"
              :class="['all-btn', 'm-1', 'py-1', 'px-2', { 'selected': letter === selectedLetter }]"
              tabindex="0"
              type="button"
              @click="filterDoctorsByLetter(letter)"
            >
              {{ letter }}
            </button>
          </div>
        </div>
        <!-- departby aplhabet -->
      </div>
    </div>
    <div class="board_of_directors">
      <div class="container">
        <div class="row gy-4">
          <div v-if="doctors.length === 0" class="col-md-12 text-center">
            <h3>No matching doctors found!</h3>
          </div>
          <!-- {{ doctors }} -->
          <!-- Loop through doctors and display them -->
          <!-- {{ doctors }} -->

          <div
            class="col-lg-3 col-md-6 col-sm-12"
            v-for="doctor in doctors"
            :key="doctor.id"
            @click="redirectToDoctorDetails(doctor.id)"
            style="cursor: pointer"
          >
            <div class="team_member">
              <div class="team_img">
                <img
                  class="doctor-image"
                  :src="
                    doctor.profile_photo
                      ? `${this.$apiBaseUrl}fdrives/sid/cityhospital/doctors/${doctor.profile_photo}`
                      : defaultImage
                  "
                  :alt="doctor.name"
                  style="width: 100%"
                />
              </div>
              <div class="team_details text-center">
                <div class="team_name py-2">
                  <strong>{{ doctor.name }}</strong>
                </div>
                <div class="details">
                  <p style="color: white">
                    {{ truncateText(doctor.degree, 4) }}
                  </p>
                </div>
                <div class="text-center doctor_btn py-3" style="color: green">
                  <a @click.prevent="redirectToDoctorDetails(doctor.id)"
                    >view Profile</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PageTitle from "./PageTitle.vue";

export default {
  name: "DoctorsList",
  components: {
    PageTitle,
  },
  data() {
    return {
      doctors: [],
      filteredDoctors: [],
      letters: [
        "All",
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      departments: [],
      doctorName: "",
      selectedDepartment: "",
      selectedLetter: "All",
      defaultImage:
        "https://cityhospital.autofyapps.com/fdrives/sid/cityhospital/doctor-profile/doctor_s47116.png",
    };
  },

  methods: {
    async fetchDepartmentDoctors() {
      try {
        let url =
          this.$apiBaseUrl +
          `pip/pip_api/doctors?access_key=` +
          this.$apiAccessKey;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(
            `HTTP error not getting data from doctors api! Status: ${response.status}`
          );
        }

        const result = await response.json();
        const data = result.doctors || [];
        // Ensure the data is an array
        this.doctors = Array.isArray(data) ? data : [];
        // console.log("Doctors list Data:", this.doctors);
      } catch (error) {
        console.error("Error fetching doctors list:", error);
      }
    },

    loadFromLocalStorage() {
      const storedData = localStorage.getItem("doctorsList");
      if (storedData) {
        this.doctors = JSON.parse(storedData);
      } else {
        this.fetchDepartmentDoctors();
      }
    },

    async searchDoctor() {
      try {
        let query =
          this.$apiBaseUrl +
          `pip/pip_api/doctors?order_by=title&order=asc&access_key=` +
          this.$apiAccessKey;

        if (this.doctorName) query += `&keyword=${this.doctorName}`;
        if (this.selectedDepartment)
          query += `&website_content_id=${this.selectedDepartment}`;

        let url = query;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        const data = result?.doctors || [];
        // Ensure the data is an array
        this.doctors = Array.isArray(data) ? data : [];
        // console.log("Searched Doctors list:", this.doctors);
      } catch (error) {
        console.error("Error searching for doctors:", error);
      }
    },

    async fetchDepartmentInfo() {
      try {
        let url =
          this.$apiBaseUrl +
          `website/website_api/contents/department?order_by=title&order=asc&access_key=` +
          this.$apiAccessKey +
          `&limit=100`;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        const data = result?.contents;
        this.departments = data;
        // console.log("Departments:", this.departments);
      } catch (error) {
        console.error("Error fetching department data:", error);
      }
    },
    // New method to filter departments by the first letter
    filterDoctorsByLetter(letter) {
      this.selectedLetter = letter; // Update the selected letter

      // If the letter is 'All', show all doctors
      if (letter === "All") {
        this.fetchDepartmentDoctors(); // Fetch all doctors
      } else {
        // Find the department that starts with the selected letter
        const selectedDepartment = this.departments.find((dept) =>
          dept.title.startsWith(letter)
        );

        if (selectedDepartment) {
          // Construct the API query with the selected department's website_content_id
          let query =
            this.$apiBaseUrl +
            `pip/pip_api/doctors?order_by=title&order=asc&access_key=` +
            this.$apiAccessKey +
            `&website_content_id=${selectedDepartment.id}`;

          // Add the doctor's name filter if it is provided
          if (this.doctorName) {
            query += `&keyword=${this.doctorName}`;
          }

          // Fetch the filtered data from the API
          fetch(query, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
              }
              return response.json();
            })
            .then((result) => {
              const data = result?.doctors || [];
              // Ensure the data is an array and update the doctors list
              this.doctors = Array.isArray(data) ? data : [];
              this.filteredDoctors = this.doctors;
            })
            .catch((error) => {
              console.error("Error fetching doctors by department:", error);
            });
        } else {
          // If no department matches the letter, clear the doctors list
          this.doctors = [];
          this.filteredDoctors = [];
        }
      }
    },

    redirectToDoctorDetails(doctorId) {
      this.$router.push({ name: "DoctorDetails", params: { id: doctorId } });
    },
    truncateText(text, wordLimit) {
      if (!text) return "";
      const words = text.split(" ");
      return words.length > wordLimit
        ? words.slice(0, wordLimit).join(" ") + "..."
        : text;
    },
  },

  mounted() {
    this.loadFromLocalStorage();
    this.fetchDepartmentInfo();
  },
};
</script>

<style>

</style>

<!--  :src="`${apiBaseUrl}${doctor.profile_photo}`" -->
